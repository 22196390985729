import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import AngularForm from '../components/convertkit/angular-form';
const { Disqus } = require('gatsby-plugin-disqus');
import './post.scss';

const PostPage = ({ data }: any) => {
  const post = data.markdownRemark;

  const disqusConfig = {
    identifier: post.id,
    title: post.frontmatter.title
  };

  return (
    <Layout>
      <div className="mcd-post">
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description}
        />
        <h1>{post.frontmatter.title}</h1>
        <small>Published: {post.frontmatter.date}</small>
        <div dangerouslySetInnerHTML={{ __html: post.html }}></div>
        <Disqus config={disqusConfig} />
      </div>
    </Layout>
  );
};

export default PostPage;

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        description
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`;
